<template>
  <div class="cameraLocationText">
    <span               
      v-tooltip="status"
      data-toggle="tooltip"
      data-placement="top"
    > 
      <font-awesome-icon
        class="gray mr-2"
        :icon="statusClass == 'green' ? 'check-circle' : 'exclamation-triangle'"
        :style="{ color: statusClass }"
      />
    </span>
    <span 
      v-if="tooltip" 
      v-tooltip="tooltip"
      data-toggle="tooltip"
      data-placement="top"
      :class="statusClass"
    >{{ location }}</span>
    <span
      v-else
      :class="statusClass"
    >{{ location }}</span>
  </div>
</template>

<script>
export default {
  name: "CameraLocationText",
  props: {
    location: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  computed: {
    statusClass: function () {
      let tmpStatus = this.status.toLowerCase();
      if (tmpStatus === 'unknown' || tmpStatus === 'failure') {
        return 'red';
      }
      return 'green';
    }
  }
}
</script>
